import { http } from './config'
import authHeader from './auth-header';

export default {


    lista: () => {
        return http.get('roles.lista', { headers: authHeader() } )
    },

    user: (id) => {
        return http.get('roles.user/'+ id, { headers: authHeader() } )
    },
   

}
